<template>
  <ModuleAuthorization
    title="Регистрация"
    description="Введите номер телефона, на который мы отправим СМС с кодом для регистрации"
  >

    <template #under-form>
      <div class="fs-policy">
        Продолжая, я соглашаюсь с
        <a href="/terms_of_use.pdf" target="_blank">
          Условиями использования
        </a>
        и
        <a href="/terms_of_use.pdf" target="_blank">
          Политикой конфиденциальности
        </a>
      </div>
    </template>

    <template #bottom>
      <span>У Вас уже есть аккаунт?</span>
      <router-link
        :to="{ name: $routeName.AUTHORIZATION }"
        class="link link--blue"
      >
        Войти
      </router-link>
    </template>

  </ModuleAuthorization>
</template>

<script>
import ModuleAuthorization from "@component/Module/Authorization";

export default {
  name: "Registration",
  components: {
    ModuleAuthorization
  }
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh;
  min-height: 800px;
}
</style>



